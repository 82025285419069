import { Modal, ConfigProvider } from 'antd';
import { Component, cloneElement } from "react";
import { getAppConfig } from '../../../core/utils';
/*
antd5
import 'antd/es/modal/style/index.less'; */
import './PcxPageModal.css';
import DragM from "dragm";
import React from 'react';
class BuildTitle extends React.Component {
  updateTransform = transformStr => {
    this.modalDom.style.transform = transformStr;
  };
  componentDidMount() {
    this.modalDom = document.getElementsByClassName(
      "pcx-page-modal"//modal的class是ant-modal-wrap
    )[0];
  }
  render() {
    const { title } = this.props;
    return (<DragM updateTransform={this.updateTransform}>
      <div>{title}</div>
    </DragM>)
  }
}
export default class PcxPageModal extends Component {
  constructor(props) {
    super(props);
    let win = getAppConfig('window');
    this.state = {
      visible: true,
      title: props.title || win.navigationBarTitleText || "对话框"
    };
  }

  handleCancel = e => {
    this.setState({
      visible: false,
    });
    if (this.page) {
      this.page.navigateBack ? this.page.navigateBack() : this.page.wxPageDeclaration.$page?.navigateBack();
    }
  };

  render() {
    let title = this.state.title;
    let { currentTheme, currentLocale, ...otherProps } = this.props

    ConfigProvider.config({
      theme: currentTheme
    });

    let parentPageConfigContext = this.props?.children?.props?.ownerPage?.configContext || {};

    let children = cloneElement(this.props.children, {
      ref: (wxReactPageElement) => {
        if (wxReactPageElement) {
          let titleState = this.props.title || (wxReactPageElement.getPageConfig ? (wxReactPageElement.getPageConfig().navigationBarTitleText || title) : title);
          if (this.state.title != titleState) {
            this.setState({ "title": titleState });
          }
          wxReactPageElement.configContext = wxReactPageElement.configContext || {};
          Object.assign(wxReactPageElement.configContext, parentPageConfigContext);
        }
        this.page = wxReactPageElement;
      }
    });

    if (this.props.draggable)
      title = <BuildTitle title={title} />

    let result = (
      <ConfigProvider
        locale={currentLocale}
        theme={currentTheme}
        getPopupContainer={() => { return document.querySelector('#pcx-page-root') }}
        renderEmpty={() => { return <></>; }}
      >
        <Modal visible={this.state.visible} className={"x-pc pcx-page pcx-page-modal"}
          destroyOnClose={true} onCancel={this.handleCancel}
          width={"80%"}
          getContainer={() => document.querySelector('#pcx-page-root')}
          footer={null} {...otherProps} title={title}
        >
          {children}
        </Modal>
      </ConfigProvider>
    )
    return result;
  }
}
